import {toCamelCase} from "../tools";
import Mustache from "mustache";

export default class PasswordChecker {
    _defConfig = {
        fieldAttribute: 'pass-check',
        fieldWrapperId: 'pass-check-wrapper',
        infoBoxTemplate: '<div class="infobox {{wrapperClass}}"><header><span class="ico fs-4"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path></svg></span>{{title}}</header>{{{content}}}</div>',
        matcher: {
            lowerCase: {title: 'małe litery', regex: /[a-zążśźęćńół]+/},
            upperCase: {title: 'duże litery', regex: /[A-ZĄŻŚŹĘĆŃÓŁ]+/},
            numbers: {title: 'cyfry', regex: /[0-9]+/},
            specials: {title: 'znaki specjalne', regex: /[\s\.,/;:'\{\[\]\}\+\-!%&@#$^*?_~]+/},
            minlength: {title: 'minimum 8 znaków', regex: /[\S]{8,}/},
            compare: {title: 'potwierdzenie hasła', compareInput: 'password-confirm'}
        }
    }

    constructor(config = {}) {
        this._config = Object.assign({}, config, this._defConfig);
    }

    start() {
        window.addEventListener('DOMContentLoaded', () => {
            this.searchFieldsByDataAttribs();
        })
    }

    generateValidationBoxContent() {
        let content = '';
        for (let key in this._config.matcher) {
            content += '<li class="fs-5 mb-1 match-' + key + '">\n' + '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-check" viewBox="0 0 16 16">\n' + '<path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>\n' + '</svg>\n' + this._config.matcher[key].title + '</li>';
        }

        return '<ul class="d-flex flex-column --clear">' + content + '</ul>';
    }

    checkRules(inputFieldEl) {
        for (let key in this._config.matcher) {
            if (!this._config.matcher[key].compareInput || inputFieldEl.getAttribute('id') === this._config.matcher[key].compareInput) {
                this.checkRule(key, inputFieldEl);
            }
        }
    }

    checkRule(ruleName, inputFieldEl) {
        if (this._config.matcher[ruleName]) {
            const ruleEl = document.querySelector('#' + this._config.fieldWrapperId + ' .match-' + ruleName.trim() + ' svg path');
            if (this._config.matcher[ruleName].regex) {
                ruleEl.setAttribute('fill', inputFieldEl.value.match(this._config.matcher[ruleName].regex) ? 'green' : 'red');
            } else if (this._config.matcher[ruleName].compareInput) {
                ruleEl.setAttribute('fill', document.getElementById(inputFieldEl.dataset.sourceFieldId).value.length && document.getElementById(inputFieldEl.dataset.sourceFieldId).value === document.getElementById(this._config.matcher[ruleName].compareInput).value ? 'green' : 'red');
            }
        }
    }

    getCompareFieldId() {
        if (!this._config.matcher.compare) {
            return false;
        }
        return this._config.matcher.compare.compareInput;
    }

    searchFieldsByDataAttribs() {
        document.querySelectorAll('input[type="password"][data-' + this._config.fieldAttribute + ']').forEach(input => {
            let datasetValues;
            try {
                datasetValues = JSON.parse(input.dataset[toCamelCase(this._config.fieldAttribute)]);
            } catch (err) {
                datasetValues = {
                    wrapperClass: 'mt-0',
                    wrapperId: input.dataset[toCamelCase(this._config.fieldAttribute)]
                };
            }
            const fWrapperId = datasetValues.wrapperId,
                data = Object.assign({}, {
                        title: 'Weryfikacja hasła',
                        content: this.generateValidationBoxContent()
                    },
                    datasetValues),
                compareFieldId = this.getCompareFieldId();

            input.addEventListener('keyup', e => {
                this.checkRules(e.target);
            });

            document.getElementById(fWrapperId ?? this._config.fieldWrapperId).innerHTML = Mustache.render(this._config.infoBoxTemplate, data);

            this.checkRules(input);

            if (compareFieldId && document.getElementById(compareFieldId)) {
                document.getElementById(compareFieldId).dataset.sourceFieldId = input.getAttribute('id');
                document.getElementById(compareFieldId).addEventListener('keyup', e => {
                    this.checkRule('compare', e.target);
                });
                this.checkRule('compare', document.getElementById(compareFieldId));
            }

        });
    }

    /***
     *
     */
    checkerInitialize(field) {
        if (!field) {
            return false;
        }
    }
}
